import { Box, ThemeProvider, Typography } from "@mui/material";
import Wordmark from "assets/images/wordmark.svg?react";
import { useTranslation } from "react-i18next";
import { darkTheme } from "styles/theme";

const DesktopExperience = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: 'white',
          minHeight: '100vh',
        }}
      >
        <Box
          sx={{
            backgroundColor: "#000000",
            marginBottom: 2,
            color: "#FFFFFF",
            padding: 2,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Wordmark height={24} style={{ alignSelf: "center" }} />
          <Typography variant="caption" sx={{ textAlign: "center", mt: 2 }}>
            {t(
              "desktop-experience.description.for-mobile",
              "You are viewing a desktop simulation of an experience designed for mobile.",
            )}
          </Typography>
          <Typography variant="caption" sx={{ textAlign: "center" }}>
            {t(
              "desktop-experience.description.open-on-mobile",
              "Please open this page in a mobile browser for the best experience.",
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '390px',
            height: '844px',
            margin: '12px',
            border: '1px solid #ddd',
            borderRadius: '12px',
            overflow: 'hidden',
            position: 'relative',
            '@media (max-width: 768px)': {
              width: '100%',
              height: '100%',
              margin: 0,
              border: 'none',
              borderRadius: 0,
            }
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              overflow: 'auto',
              backgroundColor: '#000000',
            }}
          >
            <Box sx={{ maxWidth: '390px', margin: '0 auto', position: 'relative' }}>
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default DesktopExperience;
