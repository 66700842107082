import { NETWORK } from "utils/chains";

export interface TTag {
  updated_at: number;
  created_at: number;
  uid: string;
  template_id: number;
  key: string;
  shareable: boolean;
  name?: string;
  description?: string;
  image_url?: string;
  animation_url?: string;
  metadata?: TMetadata;
  nft_contract_address?: string;
  nft_chain_id?: NETWORK;
  nft_token_id?: number;
  contract_version?: PrimaryContractVersion;
  counter?: number | null;
  login_type: LoginType;
  redirect_url?: string;
  header_logo_url?: string;
  builder_io_api_key?: string;
  builder_io_url?: string;
  owner_address?: string | null;
  owner_email?: string | null;
  email_marketing_consent: boolean;
  // For claim:
  hash?: string;
}

export enum LoginType {
  OFF = 0,
  EMAIL_SOCIAL = 1,
  EMAIL_SOCIAL_WEB3 = 2,
}

export type TMetadataOptions = Record<string, string> & {
  theme: string;
};

export interface TMetadata {
  type?: "lgt_content";
  data?: [];
  options?: TMetadataOptions;
  version?: "2.0.0" | "1.0.0";
}

export enum LGTContractVersion {
  LEGACY_UNSUPPORTED = 0,
  V3_BASE_URI = 1,
  V3_PSI_BASE_URI = 2,
  V3_PSI_ON_CHAIN_METADATA = 3,
}

export type PrimaryContractVersion =
  | LGTContractVersion.V3_BASE_URI
  | LGTContractVersion.V3_PSI_BASE_URI
  | LGTContractVersion.V3_PSI_ON_CHAIN_METADATA
  | LGTContractVersion.LEGACY_UNSUPPORTED;
