import { API_URL } from "config";
import { get, post } from "utils/api";

export const fetchTagVerification = (
  uid: string,
  searchParams: URLSearchParams,
) => {
  return get(`${API_URL}/tags/${uid}/verification?${searchParams.toString()}`);
};

export const fetchTagPublic = (uid: string) => {
  return get(`${API_URL}/tags/${uid}/public`);
};

export const fetchTagVerifications = (uid: string, page?: number) => {
  const params = new URLSearchParams({ page_size: "4" });

  if (page) {
    params.append("page", page.toString());
  }

  return get(
    `${API_URL}/external/v1/verifications/${uid}?${params.toString()}`,
  );
};

export const fetchTagHistory = (uid: string, page?: number) => {
  const params = new URLSearchParams({ page_size: "4" });

  if (page) {
    params.append("page", page.toString());
  }

  return get(`${API_URL}/external/v1/transfers/${uid}?${params.toString()}`);
};

interface ClaimTagArgs {
  ctr: string;
  cmac: string;
  uid: string;
  account: string;
  email?: string;
}

export const claimTag = ({ ctr, cmac, uid, account, email }: ClaimTagArgs) => {
  return post(`${API_URL}/tags/${uid}/claim`, {
    wallet_address: account,
    ctr,
    cmac,
    uid,
    email: email,
  });
};

interface ResetTagOwnershipArgs {
  cmac: string;
  ctr: string;
  uid: string;
}

export const resetTagOwnership = ({
  cmac,
  ctr,
  uid,
}: ResetTagOwnershipArgs) => {
  return post(`${API_URL}/tags/${uid}/recover`, {
    uid,
    cmac,
    ctr,
  });
};

interface UpdateMarketingConsentArgs {
  uid: string;
  cmac: string;
  ctr: string;
  consent: boolean;
}

export const updateMarketingConsent = ({
  uid,
  cmac,
  ctr,
  consent,
}: UpdateMarketingConsentArgs) => {
  return post(`${API_URL}/tags/${uid}/marketing_consent`, {
    uid,
    cmac,
    ctr,
    email_marketing_consent: consent,
  });
};
